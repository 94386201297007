<template>
  <div class="component-routes">
    <template v-if="show_switcher">
      <div class="container !max-w-screen-sm">
        <div class="flex flex-wrap justify-end gap-4 w-full">
          <div class="flex flex-wrap gap-2 items-center" v-if="show_switcher">
            <div
              @click="change_style_to('swiper')"
              :class="[
                'btn',
                style === 'swiper' ? 'btn-dark' : 'btn-link',
                'w-10',
                'h-10'
              ]"
            >
              <i class="fi fi-rr-card-heart text-xl"></i>
            </div>

            <div
              @click="change_style_to('list')"
              :class="[
                'btn',
                style === 'list' ? 'btn-dark' : 'btn-link',
                'w-10',
                'h-10'
              ]"
            >
              <i class="fi fi-rr-ballot text-xl"></i>
            </div>
          </div>
        </div>
      </div>
    </template>

    <div v-if="ready_show_swiper" class="container !max-w-screen-sm">
      <div class="w-full">
        <swiper-container
          class="routes-container"
          slides-per-view="auto"
          :space-between="8"
          :centeredSlides="true"
          :loop="true"
          :initialSlide="1"
          :breakpoints="breakpoints"
        >
          <swiper-slide
            v-for="route in parsed_routes"
            :key="route.id"
            class="item animate__animated animate__fadeIn"
          >
            <route_component
              v-if="route.type === 'route'"
              :route="route"
              :on_click="on_click"
            />
            <skeleton v-if="route.type === 'skeleton'" />
            <empty v-if="route.type === 'empty'" />
          </swiper-slide>
        </swiper-container>
      </div>
    </div>

    <div v-if="ready_show_list">
      <div class="container !max-w-screen-sm">
        <div class="flex flex-col gap-2 w-full">
          <route_component
            route_style="card"
            v-for="route in parsed_routes.filter((x) => x.type === 'route')"
            :key="route.id"
            :route="route"
            :on_click="on_click"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  // Components
  import route_component from './route.vue'
  import skeleton from './skeleton.vue'
  import empty from './empty.vue'

  // Libs
  import { toRefs, watch, ref, onMounted, computed } from 'vue'

  // Props
  const props = defineProps({
    loading: {
      default: true
    },
    default_style: {
      default: 'swiper'
    },
    show_switcher: {
      default: false
    },
    max_elements: {
      default: 15
    },
    routes: {
      default: null
    },
    breakpoints: {
      default: {
        1280: {
          slidesPerView: 3
        },
        1024: {
          slidesPerView: 3
        },
        768: {
          slidesPerView: 2
        },
        640: {
          slidesPerView: 2
        },
        320: {
          slidesPerView: 2
        }
      }
    },
    on_click: {
      default: () => {}
    }
  })

  // Variables
  // Destructure props to reactive references
  const { routes, max_elements, on_click, default_style, show_switcher } =
    toRefs(props)

  const parsed_routes = ref([])
  const style = ref(default_style.value)

  // Computed variables
  const ready_show_swiper = computed(() => {
    return parsed_routes.value.length > 0 && style.value === 'swiper'
  })

  const ready_show_list = computed(() => {
    return parsed_routes.value.length > 0 && style.value === 'list'
  })

  // Watchers
  watch(routes, () => {
    apply_parse_on_routes()
  })

  // Methods
  const apply_parse_on_routes = function () {
    let response = routes.value
      .map((route) => ({
        ...route,
        type: route.type || 'route'
      }))
      .slice(0, max_elements.value)

    while (response.length < max_elements.value) {
      response.push({ type: 'empty' })
    }

    parsed_routes.value = response
  }

  const change_style_to = function (new_style) {
    style.value = new_style
  }

  // Lifecycle
  onMounted(() => {
    while (parsed_routes.value.length < max_elements.value) {
      parsed_routes.value.push({ type: 'skeleton' })
    }

    window.addEventListener('resize', () => {
      apply_parse_on_routes()
    })
  })
</script>
